var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-title',[_c('span',[_vm._v(_vm._s(_vm.opdData.opd_code))]),_vm._v(" "),_c('v-spacer'),_vm._v(" "+_vm._s(_vm.opdData.room_name)+" ")],1),_c('v-data-table',{attrs:{"headers":_vm.columns,"items":_vm.cartData.cart,"options":_vm.options,"dense":"","loading":_vm.loading,"fixed-header":"","disable-sort":"","hide-default-footer":"","height":"450","loading-text":_vm.$t('data_loading_please_wait'),"no-data-text":_vm.loadingData?_vm.$t('data_loading_please_wait'):_vm.$t('no_information')},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.checklist",fn:function(ref){
var item = ref.item;
return [_c('ol',_vm._l((item.checklist),function(i,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(i.checklist_name)+" ")])}),0)]}},{key:"item.opdchecking_discount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatPrice")(item.opdchecking_discount))+" ")]}},{key:"item.opdchecking_temp_fee",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"my-2",attrs:{"outlined":"","hide-details":"","reverse":"","dense":"","type":"number"},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.updateFeeDoctor(item)},"blur":function($event){return _vm.updateFeeDoctor(item)}},model:{value:(item.opdchecking_temp_fee),callback:function ($$v) {_vm.$set(item, "opdchecking_temp_fee", $$v)},expression:"item.opdchecking_temp_fee"}})]}},{key:"item.opdchecking_temp_cost",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatPrice")(item.opdchecking_temp_cost))+" ")]}}],null,true)}),_c('v-divider',{staticClass:"mb-2"}),_c('v-row',{staticClass:"px-2 mb-2"},[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('p',{staticClass:"mb-0  font-weight-semibold"},[_vm._v(" "+_vm._s(_vm.$t("note"))+" ")]),_c('quill-editor',{ref:"myTextEditor",staticClass:"pb-2",attrs:{"options":_vm.editorOption},model:{value:(_vm.opdData.opd_comment),callback:function ($$v) {_vm.$set(_vm.opdData, "opd_comment", $$v)},expression:"opdData.opd_comment"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('p',{staticClass:"mb-0  font-weight-semibold"},[_vm._v(" "+_vm._s(_vm.$t("summaryTotal"))+" ")]),_c('v-divider'),_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',[_c('td',{staticClass:"font-weight-bold text-right"},[_vm._v(" "+_vm._s(_vm.$t("including_doctor_fees"))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatPrice")(_vm.cartData.check_sum_fee))+" ")])]),_c('tr',[_c('td',{staticClass:"font-weight-bold text-right"},[_vm._v(" "+_vm._s(_vm.$t("total_examination"))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatPrice")(_vm.cartData.check_sum))+" ")])])])]},proxy:true}])}),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.cartData.cart.length == 0||_vm.loadingUpdate,"loading":_vm.loadingUpdate},on:{"click":_vm.updateChecking}},[_vm._v(" "+_vm._s(_vm.$t("save"))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }