<template>
  <v-dialog
    v-model="isPrintLabLabel"
    persistent
    max-width="500"
  >
    <v-card>
      <v-card-title>
        {{ $t('printing_lab_labels') }}
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="labNumber"
          outlined
          dense
          :label="$t('lab_number')"
        >
        </v-text-field>
        <label>
          {{ $t("choose_language") }}
        </label>
        <v-radio-group
          v-model="language"
        >
          <v-radio
            label="ไทย"
            value="1"
          ></v-radio>
          <v-radio
            label="English"
            value="2"
          ></v-radio>
        </v-radio-group>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="sendPrint"
        >
          {{ $t('confirm') }}
        </v-btn>
        <v-btn
          color="secondary"
          outlined
          @click="$emit('update:isPrintLabLabel',false)"
        >
          {{ $t('cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import check from '@/api/queue/checkDetail'
import document from '@/api/systemSetting/document'
import router from '@/router'

export default {
  model: {
    prop: 'isPrintLabLabel',
    event: 'update:isPrintLabLabel',
  },
  props: {
    isPrintLabLabel: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const amount = ref(1)
    const labNumber = ref('')
    const language = ref('1')
    const statusCheckLab = ref(false)

    const getDocs = () => {
      document.documentSetting().then(res => {
        labNumber.value = res.lab
      })
    }

    const checkPrintLab = () => {
      check.checkingGet(props.id).then(res => {
        if (res.opd_lab_code) {
          statusCheckLab.value = false
        } else {
          statusCheckLab.value = true
        }
      })
    }

    const sendPrint = () => {
      const routeData = router.resolve({
        name: 'printLabLabel',
        query: {
          id: props.id,
          code: labNumber.value,
          lang: language.value,
        },
      })
      emit('onPrint')
      emit('update:isPrintLabLabel', false)
      window.open(routeData.href, '_blank')
    }

    watch(() => props.isPrintLabLabel, value => {
      if (value) {
        getDocs()
        amount.value = '1'
        language.value = '1'
        checkPrintLab()
      }
    })

    return {
      amount,
      labNumber,
      language,
      statusCheckLab,
      sendPrint,
    }
  },
}
</script>
