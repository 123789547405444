import { i18n } from '@/plugins/i18n'
import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'

const editCheckGet = async id => {
  //await refreshToken()
  const response = await api
    .get({
      path: 'check/checkedit',
      param: `${id}/${i18n.locale}`,
    })
    .then(res => res.data || {})
    .catch(err => {
      console.error('get check list err : ', err)

      return {}
    })

  return response
}

const checkEditCartList = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'check/carteditlist',
      body,
    })
    .then(res => res.data || [])
    .catch(err => {
      console.error('get cart check list err : ', err)

      return []
    })

  return response
}

const checkEditCartUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'check/cartedit',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('update cart checking edit : ', err)

      return {}
    })

  return response
}

const checkEditUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'check/checkedit',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('update checking edit : ', err)

      return {}
    })

  return response
}

const changeRoomDoctor = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'checkq/update',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('update room and  doctor : ', err)

      return {}
    })

  return response
}

export default {
  editCheckGet,
  checkEditCartList,
  checkEditCartUpdate,
  checkEditUpdate,
  changeRoomDoctor,
}
