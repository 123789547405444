import customer from '@/api/customer/customer'
import checkDetail from '@/api/queue/checkDetail'
import editCheck from '@/api/queue/editCheck'
import router from '@/router'
import { useRouter } from '@core/utils'
import {
  mdiFileOutline,
  mdiFlaskOutline,
  mdiFormatListBulleted,
  mdiImage,
  mdiImageOutline,
  mdiStethoscope,
} from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'

export default function useEditChecking() {
  const { route } = useRouter()
  const opdData = ref({})
  const getCheckingLoading = ref(true)
  const getCustomerLoading = ref(true)
  const customerData = ref({})
  const profile = ref(false)
  const isShowFaceCart = ref(false)
  const cartData = ref({})
  const tabs = ref([])
  const opdTab = ref(route.value.query.status ? 2 : 0)
  const isAddCertDoctor = ref(false)
  const isAddSendBodySick = ref(false)
  const isAddCertSick = ref(false)
  const isPrintCertDoctorOption = ref(false)
  const certDoctorIdList = ref([])
  const isPrintCertSickOption = ref(false)
  const certSickIdList = ref([])
  const { checkEditCartList } = editCheck
  const { customerById } = customer
  const { checkingGet } = checkDetail

  const printCertDoctor = message => {
    certDoctorIdList.value = []
    certDoctorIdList.value.push(message)
    isPrintCertDoctorOption.value = true
  }

  const printCertSick = message => {
    certSickIdList.value = []
    certSickIdList.value.push(message)
    isPrintCertSickOption.value = true
  }

  onMounted(() => {
    getChecking()
  })

  const getChecking = () => {
    checkingGet(router.currentRoute.params.id).then(res => {
      opdData.value = res
      if (res.checklab_add_id == 1 || res.checklab_edit_id == 1) {
        tabs.value = [
          { icon: mdiStethoscope, title: 'basic_information_examination' },
          { icon: mdiFormatListBulleted, title: 'examination_list' },
          { icon: mdiFlaskOutline, title: res.checklab_add_id == 1 ? 'lab_results_record' : 'lab_results_edit' },
          { icon: mdiImage, title: 'picture_before' },
          { icon: mdiImageOutline, title: 'picture_after' },
          { icon: mdiFileOutline, title: 'show_face_opd' },
        ]
      } else {
        tabs.value = [
          { icon: mdiStethoscope, title: 'basic_information_examination' },
          { icon: mdiFormatListBulleted, title: 'examination_list' },
          { icon: mdiImage, title: 'picture_before' },
          { icon: mdiImageOutline, title: 'picture_after' },
          { icon: mdiFileOutline, title: 'show_face_opd' },
        ]
      }
      getCustomer(res.customer_id_pri)
      getCheckCartList()
    })
  }

  const getCheckCartList = () => {
    checkEditCartList({
      opd_id: router.currentRoute.params.id,
    }).then(res => {
      cartData.value = res
      getCheckingLoading.value = false
    })
  }

  const getCustomer = id => {
    customerById(id).then(res => {
      customerData.value = res
      getCustomerLoading.value = false
    })
  }

  const printMedical = () => {
    opdData.value.statusPrint = 1
  }
  const printCertificate = () => {
    opdData.value.statusPrint = 1
  }
  const printPatient = () => {
    opdData.value.statusPrint = 1
  }

  const onAddPrintCertDoctor = id => {
    certDoctorIdList.value.push(id)
    isPrintCertDoctorOptions.value = true
  }

  return {
    opdData,
    getCheckingLoading,
    getCustomerLoading,
    customerData,
    profile,
    isShowFaceCart,
    tabs,
    opdTab,
    cartData,
    printMedical,
    printCertificate,
    printPatient,
    getCheckCartList,
    getChecking,
    isAddCertDoctor,
    isAddSendBodySick,
    isAddCertSick,
    isPrintCertDoctorOption,
    certDoctorIdList,
    isPrintCertSickOption,
    certSickIdList,
    printCertSick,
    printCertDoctor,
  }
}
