<template>
  <div id="user-view">
    <v-card
      :loading="getCustomerLoading && getCheckingLoading"
      min-height="600"
    >
      <v-row>
        <v-col
          v-if="!getCustomerLoading && !getCheckingLoading"
          cols="12"
          class="pb-0"
        >
          <v-card-actions
            class="pb-3 flex-column flex-sm-row justify-sm-space-between d-md-none px-1"
          >
            <span>
              <router-link
                class="font-weight-medium text-decoration-none me-2"
                :to="{ name: 'Examination-Room'}"
              >
                <v-tooltip
                  color="#212121"
                  top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      v-bind="attrs"
                      icon
                      v-on="on"
                    >
                      <v-icon>{{ icons.mdiArrowLeftBold }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("back") }}</span>
                </v-tooltip>
              </router-link> <PopupCustomer :customer-data="customerData" /></span>
            <span>
              <v-tooltip
                v-if="opdData.opd_status_id == '4' && opdData.opd_check == '1'"
                color="#212121"
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="warning"
                    icon
                    v-bind="attrs"
                    outlined
                    class="mx-1"
                    v-on="on"
                    @click="createReceipt(opdData.opd_id, opdData.opd_date)"
                  >
                    <v-icon>{{ icons.mdiReceipt }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("create_receipt") }}</span>
              </v-tooltip>

              <v-tooltip
                v-if="opdData.opd_status_id != '0'"
                color="#212121"
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="info"
                    icon
                    class="mx-1"
                    outlined
                    v-bind="attrs"
                    @click="isShowFaceCart = true"
                    v-on="on"
                  >
                    <v-icon>{{ icons.mdiClipboardAccountOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("system_face_chart") }}</span>
              </v-tooltip>

              <v-tooltip
                v-if="opdData.add_phrf == 1"
                color="#212121"
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="accent"
                    outlined
                    class="mx-1"
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="isAddSendBodySick = true"
                  >
                    <v-icon>{{ icons.mdiBadgeAccountOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>  {{ $t("add")
                }}{{ $t("pharmacist_referral_form") }}</span>
              </v-tooltip>

              <v-tooltip
                v-if="opdData.print_phrf == 1"
                color="#212121"
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="warning"
                    outlined
                    class="mx-1"
                    v-bind="attrs"
                    v-on="on"
                    @click="print1"
                  >
                    <v-icon>{{ icons.mdiPrinter }}</v-icon>
                  </v-btn>
                </template>
                <span>      {{ $t("print")
                }}{{ $t("pharmacist_referral_form") }}</span>
              </v-tooltip>

              <v-tooltip
                v-if="opdData.add_sick == 1"
                color="#212121"
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    class="mx-1"
                    outlined
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="isAddCertSick = true"
                  >
                    <v-icon>{{ icons.mdiFilePlus }}</v-icon>
                  </v-btn>
                </template>
                <span>       {{ $t("add")
                }}{{ $t("illness_certificate") }}</span>
              </v-tooltip>

              <v-tooltip
                v-if="opdData.print_sick == 1"
                color="#212121"
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    outlined
                    v-bind="attrs"
                    class="mx-1"
                    v-on="on"
                    @click="dialogSelectLang2 = true"
                  >
                    <v-icon>{{ icons.mdiPrinter }}</v-icon>
                  </v-btn>
                </template>
                <span>         {{ $t("print")
                }}{{ $t("illness_certificate") }}</span>
              </v-tooltip>

              <v-tooltip
                v-if="opdData.add_cert == 1"
                color="#212121"
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    color="success"
                    outlined
                    class="mx-1"
                    icon
                    v-on="on"
                    @click="isAddCertDoctor = true"
                  >
                    <v-icon>{{ icons.mdiFilePlusOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>       {{ $t("add")
                }}{{ $t("medical_certificate") }}</span>
              </v-tooltip>

              <v-tooltip
                v-if="opdData.print_cert == 1"
                color="#212121"
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="success"
                    icon
                    v-bind="attrs"
                    class="mx-1"
                    outlined
                    v-on="on"
                    @click="showOptionPrint()"
                  >
                    <v-icon>{{ icons.mdiPrinter }}</v-icon>
                  </v-btn>
                </template>
                <span>          {{ $t("print")
                }}{{ $t("medical_certificate") }}</span>
              </v-tooltip>

              <v-tooltip
                v-if="opdData.opd_status_id != '0' && !opdData.order_id_pri"
                color="#212121"
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="error"
                    icon
                    v-bind="attrs"
                    class="mx-1"
                    outlined
                    v-on="on"
                  >
                    <v-icon>{{ icons.mdiClose }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("cancel") }}</span>
              </v-tooltip>
            </span>
          </v-card-actions>
          <v-card-actions
            class="pb-2 px-2 flex-column flex-lg-row justify-lg-space-between d-none d-md-flex"
          >
            <span>
              <router-link
                class="font-weight-medium text-decoration-none me-2"
                :to="{ name: 'Examination-Room'}"
              >
                <v-tooltip
                  color="#212121"
                  top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      v-bind="attrs"
                      icon
                      v-on="on"
                    >
                      <v-icon>{{ icons.mdiArrowLeftBold }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("back") }}</span>
                </v-tooltip>
              </router-link> <PopupCustomer :customer-data="customerData" /></span>
            <span align="center">
              <v-btn
                v-if="opdData.opd_status_id == '4' && opdData.opd_check == '1'"
                color="warning"
                class="mx-1 mt-1"
                @click="createReceipt(opdData.opd_id, opdData.opd_date)"
              >
                {{ $t("create_receipt") }}
              </v-btn>
              <v-btn
                color="info"
                outlined
                class="mx-1 mt-1"
                @click="isShowFaceCart = true"
              >
                {{ $t("system_face_chart") }}
              </v-btn>
              <v-btn
                v-if="opdData.add_phrf == 1"
                color="accent"
                outlined
                class="mx-1 mt-1"
                @click="isAddSendBodySick = true"
              >
                {{ $t("add")
                }}{{ $t("pharmacist_referral_form") }}
              </v-btn>
              <v-btn
                v-if="opdData.print_phrf == 1"
                color="warning"
                outlined
                class="mx-1 mt-1"
                @click="print1"
              >
                {{ $t("print")
                }}{{ $t("pharmacist_referral_form") }}
              </v-btn>
              <v-btn
                v-if="opdData.add_sick == 1"
                color="primary"
                outlined
                class="mx-1 mt-1"
                @click="isAddCertSick = true"
              >
                {{ $t("add")
                }}{{ $t("illness_certificate") }}
              </v-btn>
              <v-btn
                v-if="opdData.print_sick == 1"
                color="primary"
                class="mx-1 mt-1"
                outlined
                @click="dialogSelectLang2 = true"
              >
                {{ $t("print")
                }}{{ $t("illness_certificate") }}
              </v-btn>
              <v-btn
                v-if="opdData.add_cert == 1"
                color="success"
                outlined
                class="mx-1 mt-1"
                @click="isAddCertDoctor=true"
              >
                {{ $t("add")
                }}{{ $t("medical_certificate") }}
              </v-btn>
              <v-btn
                v-if="opdData.print_cert == 1"
                color="success"
                outlined
                class="mx-1 mt-1"
                @click="showOptionPrint()"
              >
                {{ $t("print")
                }}{{ $t("medical_certificate") }}
              </v-btn>
              <v-btn
                v-if="opdData.opd_status_id != '0' && !opdData.order_id_pri"
                color="error"
                class="mx-1 mt-1"
                outlined
              >
                {{ $t("cancel") }}
              </v-btn>
            </span>
          </v-card-actions>
          <v-divider class="mt-0"></v-divider>
          <v-tabs

            v-model="opdTab"
            show-arrows
            class="user-tabs"
          >
            <v-tab
              v-for="tab in tabs"
              :key="tab.icon"
            >
              <v-icon
                size="20"
                class="me-1"
              >
                {{ tab.icon }}
              </v-icon>
              <span>{{ $t(tab.title) }}</span>
            </v-tab>
          </v-tabs>
          <v-tabs-items
            id="user-tabs-content"
            v-model="opdTab"
          >
            <v-tab-item transition="none">
              <EditDataChecking
                :opd-data="opdData"
                @onUpdate="getChecking"
              />
            </v-tab-item>

            <v-tab-item
              transition="none"
            >
              <EditCheckList
                :loading-data="getCheckingLoading"
                :cart-data="cartData"
                :opd-data="opdData"
                @onUpdate="getCheckCartList"
              />
            </v-tab-item>
            <v-tab-item
              v-if="opdData.checklab_add_id==1||opdData.checklab_edit_id==1"
              transition="none"
            >
              <LabChecking
                :loading-data="getCheckingLoading"
                :opd-data="opdData"
                @onUpdate="getChecking"
              />
            </v-tab-item>
            <v-tab-item transition="none">
              <BeforeImage :opd-data="opdData" />
            </v-tab-item>
            <v-tab-item transition="none">
              <AfterImage :opd-data="opdData" />
            </v-tab-item>
            <v-tab-item transition="none">
              <FacecartDoc :opd-data="opdData" />
            </v-tab-item>
          </v-tabs-items>
        </v-col>
        <v-col
          v-else
          cols="12"
        >
          <div
            align="center"
            class="mt-5"
          >
            {{ $t('data_loading') }}
          </div>
        </v-col>
      </v-row>
    </v-card>
    <v-dialog
      v-model="isShowFaceCart"
      persistent
      max-width="1920"
    >
      <v-card>
        <v-card-text class="p-0">
          <FaceChart v-if="isShowFaceCart"></FaceChart>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            color="secondary"
            @click="isShowFaceCart = false"
          >
            {{ $t("close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <CertDoctorOptions
      v-model="isAddCertDoctor"
      :opd-id="opdData.opd_id"
      @onAdd="printCertDoctor"
    />
    <SendBodySickOptions
      v-model="isAddSendBodySick"
      :opd-id="opdData.opd_id"
    />
    <CertSickOptions
      v-model="isAddCertSick"
      :opd-id="opdData.opd_id"
      @onAdd="printCertSick"
    />
    <CertDoctorOptionsPrint
      :id="certDoctorIdList"
      v-model="isPrintCertDoctorOption"
    />
    <CertSickOptionsPrint
      :id="certSickIdList"
      v-model="isPrintCertSickOption"
    />
  </div>
</template>

<script>
import {
  mdiReceipt, mdiClipboardAccountOutline, mdiArrowLeftBold, mdiBadgeAccountOutline, mdiPrinter, mdiFilePlus, mdiFilePlusOutline, mdiClose, mdiInformationOutline,
} from '@mdi/js'
import useEditChecking from './useEditChecking'
import EditDataChecking from './EditDataChecking.vue'
import BeforeImage from './BeforeImage.vue'
import AfterImage from './AfterImage.vue'
import FacecartDoc from './FacecartDoc.vue'
import FaceChart from './FaceChart.vue'
import EditCheckList from './EditCheckList.vue'
import LabChecking from './LabChecking.vue'
import PopupCustomer from '@/components/basicComponents/PopupCustomer.vue'
import CertDoctorOptionsPrint from '@/components/prints/CertDoctorOptions.vue'
import CertDoctorOptions from '@/components/certOptions/CertDoctorOptions.vue'
import SendBodySickOptions from '@/components/certOptions/SendBodySickOptions.vue'
import CertSickOptions from '@/components/certOptions/CertSickOptions.vue'
import CertSickOptionsPrint from '@/components/prints/CertSickOption.vue'

export default {

  components: {
    EditDataChecking,
    BeforeImage,
    AfterImage,
    FacecartDoc,
    EditCheckList,
    FaceChart,
    LabChecking,
    PopupCustomer,
    CertDoctorOptionsPrint,
    CertDoctorOptions,
    SendBodySickOptions,
    CertSickOptions,
    CertSickOptionsPrint,
  },
  setup() {
    return {
      ...useEditChecking(),
      icons: {
        mdiReceipt,
        mdiClipboardAccountOutline,
        mdiBadgeAccountOutline,
        mdiPrinter,
        mdiFilePlus,
        mdiFilePlusOutline,
        mdiClose,
        mdiInformationOutline,
        mdiArrowLeftBold,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
