<template>
  <div>
    <v-card-title>
      <span>{{ opdData.opd_code }}</span> <v-spacer></v-spacer>
      {{ opdData.room_name }}
    </v-card-title>
    <v-data-table
      :headers="columns"
      :items="cartData.cart"
      :options.sync="options"
      dense
      :loading="loading"
      fixed-header
      disable-sort
      hide-default-footer
      height="450"
      :loading-text="$t('data_loading_please_wait')"
      :no-data-text="loadingData?$t('data_loading_please_wait'):$t('no_information')"
    >
      <template v-slot:[`item.checklist`]="{ item }">
        <ol>
          <li
            v-for="(i, index) in item.checklist"
            :key="index"
          >
            {{ i.checklist_name }}
          </li>
        </ol>
      </template>
      <template v-slot:[`item.opdchecking_discount`]="{ item }">
        {{ item.opdchecking_discount | formatPrice }}
      </template>
      <template v-slot:[`item.opdchecking_temp_fee`]="{ item }">
        <v-text-field
          v-model="item.opdchecking_temp_fee"
          outlined
          hide-details
          reverse
          class="my-2"
          dense
          type="number"
          @keypress.enter="updateFeeDoctor(item)"
          @blur="updateFeeDoctor(item)"
        ></v-text-field>
      </template>
      <template v-slot:[`item.opdchecking_temp_cost`]="{ item }">
        {{ item.opdchecking_temp_cost | formatPrice }}
      </template>
    </v-data-table>
    <v-divider class="mb-2"></v-divider>
    <v-row class="px-2 mb-2">
      <v-col
        cols="12"
        lg="6"
      >
        <p class="mb-0  font-weight-semibold">
          {{ $t("note") }}
        </p>
        <quill-editor
          ref="myTextEditor"
          v-model="opdData.opd_comment"
          :options="editorOption"
          class="pb-2"
        >
        </quill-editor>
      </v-col>
      <v-col
        cols="12"
        lg="6"
      >
        <p class="mb-0  font-weight-semibold">
          {{ $t("summaryTotal") }}
        </p>
        <v-divider></v-divider>
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr>
                <td class="font-weight-bold text-right">
                  {{ $t("including_doctor_fees") }}
                </td>
                <td class="text-right">
                  {{ cartData.check_sum_fee | formatPrice }}
                </td>
              </tr>
              <tr>
                <td class="font-weight-bold text-right">
                  {{ $t("total_examination") }}
                </td>
                <td class="text-right">
                  {{ cartData.check_sum | formatPrice }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :disabled="cartData.cart.length == 0||loadingUpdate"
            :loading="loadingUpdate"
            @click="updateChecking"
          >
            {{ $t("save") }}
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ref, onMounted, watch } from '@vue/composition-api'
import { quillEditor } from 'vue-quill-editor'
import { sumdate, formatPrice } from '@/plugins/filters'
import { i18n } from '@/plugins/i18n'
import editCheck from '@/api/queue/editCheck'
import router from '@/router'
import store from '@/store'

export default {

  components: {
    quillEditor,
  },

  filters: {
    sumdate,
    formatPrice,
  },
  props: {
    loadingData: {
      type: Boolean,
      default: false,
    },
    cartData: {
      type: Object,
      default: () => {},
    },
    opdData: {
      type: Object,
      default: () => {},
    },
  },

  setup(props, { emit }) {
    const editorOption = {
      placeholder: '',
      modules: {
        toolbar: {
          container: [
            [{ list: 'ordered' }, { list: 'bullet' }], // List
            [{ indent: '-1' }, { indent: '+1' }], // Indent
          ],
          handlers: {
            image(val) {
              if (val) {
                document.querySelector('.quill-img input').click()
              } else {
                this.quill.format('image', false)
              }
            },
          },
        },
      },
    }

    const loading = ref(true)
    const check_list = ref([])
    const updateLoading = ref(false)
    const cartDataLocal = ref({})
    const loadingUpdate = ref(false)
    const options = ref({})
    const columns = ref([
      {
        text: i18n.t('code'), align: 'start', value: 'opdchecking_temp_code', width: 50,
      },
      { text: i18n.t('examination_list'), value: 'opdchecking_temp_name', width: 200 },
      { text: i18n.t('examination_detail'), value: 'checklist', width: 200 },
      {
        text: i18n.t('doctor_fee'), value: 'opdchecking_temp_fee', width: 100, align: 'end',
      },
      {
        text: i18n.t('examination_fee'), value: 'opdchecking_temp_cost', width: 100, align: 'end',
      },

    ])

    const { checkEditCartUpdate, checkEditUpdate } = editCheck

    onMounted(() => {
      cartDataLocal.value = JSON.parse(JSON.stringify(props.cartData))
      options.value.page = 1
      options.value.itemsPerPage = -1
      loading.value = false
    })

    watch(() => props.cartData, value => {
      cartDataLocal.value = JSON.parse(JSON.stringify(value))
      options.value.page = 1
      options.value.itemsPerPage = -1
      loading.value = false
    })
    const updateFeeDoctor = item => {
      updateLoading.value = true
      checkEditCartUpdate({
        opd_id: router.currentRoute.params.id,
        opdchecking_temp_id: item.opdchecking_temp_id,
        opdchecking_temp_fee: item.opdchecking_temp_fee,
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        updateLoading.value = true
        emit('onUpdate')
      })
    }

    const updateChecking = () => {
      loadingUpdate.value = true
      checkEditUpdate(props.opdData).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        loadingUpdate.value = false
        emit('onUpdate')
      })
    }

    return {
      editorOption,
      loading,
      loadingUpdate,
      check_list,
      cartDataLocal,
      columns,
      options,
      updateChecking,
      updateFeeDoctor,
    }
  },

}
</script>

<style lang="scss">
.ql-toolbar.ql-snow {
    border: 1px solid #ccc;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    box-sizing: border-box;
    font-family: 'Kanit', sans-serif;
    padding: 8px;
}

.ql-container {
  box-sizing: border-box;
  font-family: 'Kanit', sans-serif;
  font-size: 16px;
  height: 200px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  margin: 0px;
  position: relative;
}

</style>
