import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'

const labList = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'check/lablist',
      body,
    })
    .then(res => (res.data || {}))
    .catch(err => {
      console.error('update lab list err : ', err)

      return {}
    })

  return response
}

const labValueUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'check/lablists',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('update lab list err : ', err)

      return {}
    })

  return response
}

const labUserUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'check/lab',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('update data lab err : ', err)

      return {}
    })

  return response
}

const labNoteUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'check/labnote',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('update data lab note err : ', err)

      return {}
    })

  return response
}

export default {
  labList, labValueUpdate, labUserUpdate, labNoteUpdate,
}
