<template>
  <div>
    <v-card-title class='py-3'>
      {{ opdDataLocal.opd_code }}
      <v-spacer></v-spacer>
      <span>
        <v-btn
          color='primary'
          :disabled='!opdDataLocal.print_lab'
          @click='isPrintResultLab = true'
        >
          <v-icon left>{{ icons.mdiPrinter }}</v-icon> {{ $t('print_lab') }}
        </v-btn>
        <v-btn
          color='info'
          class='py-0 my-0 mx-2'
          @click='isSendCheckLab = true'
        >
          <v-icon left>{{ icons.mdiPrinter }}</v-icon> {{ $t('print_lab_check_list') }}
        </v-btn>
        <v-btn
          color='info'
          class='py-0 my-0'
          @click='isPrintLabLabel = true'
        >
          <v-icon left>{{ icons.mdiPrinter }}</v-icon> {{ $t('printing_lab_labels') }}
        </v-btn>
      </span>
    </v-card-title>
    <v-data-table
      :headers='columns'
      :items='labListTable'
      :options.sync='options'
      dense
      :loading='loading'
      fixed-header
      disable-sort
      hide-default-footer
      height='450'
      :loading-text="$t('data_loading_please_wait')"
      :no-data-text="loadingData?$t('data_loading_please_wait'):$t('no_information')"
    >
      <template v-slot:[`item.opdchecking_name`]='{ item }'>
        ({{ item.opdchecking_code }}) {{ item.opdchecking_name }}
        <CheckSetType
          class='mb-1'
          :type='item.opdchecking_set_id'
        />
      </template>
      <template v-slot:[`item.detectable_value`]='{ item }'>
        <v-text-field
          v-model.trim='item.opdchecklist_value'
          outlined
          dense
          hide-details
          class='my-2'
          @blur='updateLabListCheck(item)'
        ></v-text-field>
      </template>
      <template v-slot:[`item.reference_value`]='{ item }'>
        <v-text-field
          v-model.trim='item.opdchecklist_ref'
          outlined
          disabled
          dense
          hide-details
          v-if='item.opdchecklist_ref'
        ></v-text-field>
        <v-select
          v-else
          v-model='item.opdchecklist_ref'
          outlined
          :items='item.checkref ? item.checkref : []'
          dense
          :no-data-text="$t('no_information')"
          hide-details
          return-object
          item-value='checkref_name'
          item-text='checkref_name'
          class='my-2'
          @change='updateLabListCheck(item)'
        >
          <template v-slot:append-outer>
            <v-btn
              v-if='item.opdchecklist_value == null'
              icon
              small
              color='gray'
            >
              <v-icon>{{ icons.mdiCheck }}</v-icon>
            </v-btn>
            <v-btn
              v-else
              icon
              small
              color='success'
            >
              <v-icon>{{ icons.mdiCheck }}</v-icon>
            </v-btn>
          </template>
        </v-select>


      </template>
    </v-data-table>
    <v-divider class='mb-2'></v-divider>
    <v-row class='px-2 mb-2'>
      <v-col
        cols='12'
        lg='6'
      >
        <p class='mb-0  font-weight-semibold'>
          {{ $t('suggestion') }}
        </p>
        <span class='d-flex'>
          <v-autocomplete
            v-model='note_name'
            dense
            outlined
            :label="$t('serach_suggestion')"
            :items='suggestionList'
            :search-input.sync='search'
            item-text='note_name'
            class='mb-2 me-2'
            item-value='note_name'
            hide-details
            :no-data-text="$t('no_data')"
          >
          </v-autocomplete>
          <v-btn
            color='accent'
            @click='isAddNewCheckNote = true'
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span class='d-none d-sm-block'>
              {{ $t('add_suggestion') }}
            </span>

          </v-btn>
        </span>
        <quill-editor
          ref='myTextEditor'
          v-model='opdDataLocal.opd_note'
          :options='editorOption'
          class='pb-2'
        >
        </quill-editor>
      </v-col>
      <v-col
        cols='12'
        lg='6'
      >
        <v-autocomplete
          v-model='opdDataLocal.opd_user_anlyst'
          class='mt-6'
          item-value='user_id'
          item-text='user_fullname'
          :no-data-text="$t('no_information')"
          outlined
          dense
          :label="$t('user_anlyst')"
          :items='userAnalysis'
        ></v-autocomplete>
        <v-autocomplete
          v-model='opdDataLocal.opd_user_inspector'
          item-value='user_id'
          item-text='user_fullname'
          :no-data-text="$t('no_information')"
          outlined
          dense
          :label="$t('user_inspector')"
          :items='userCheck'
        ></v-autocomplete>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color='primary'
            :loading='processUpdate'
            :disabled='processUpdate'
            @click='updateLabUser'
          >
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
    <AddNewCheckNote
      v-model='isAddNewCheckNote'
      @onAdd='getSuggestionList'
    />
    <LabLabelOptions
      :id='opdData.opd_id'
      v-model='isPrintLabLabel'
      @onPrint='onPrint'
    />
    <SendCheckLabOptions
      :id='[opdData.opd_id]'
      v-model='isSendCheckLab'
    />
    <ResultLabelOptions
      :id='[opdData.opd_id]'
      v-model='isPrintResultLab'
    />
  </div>
</template>

<script>
import { ref, onMounted, watch } from '@vue/composition-api'
import { quillEditor } from 'vue-quill-editor'
import { mdiCheck, mdiPlus, mdiPrinter } from '@mdi/js'
import { sumdate, formatPrice } from '@/plugins/filters'
import { i18n } from '@/plugins/i18n'
import lab from '@/api/queue/lab'
import CheckSetType from '@/components/basicComponents/CheckSetType.vue'
import store from '@/store'
import checkNote from '@/api/examination/checkNote'
import AddNewCheckNote from '@/views/examinitions/AddNewCheckNote.vue'
import userRole from '@/api/userRole'
import LabLabelOptions from '@/components/prints/LabLabelOptions.vue'
import SendCheckLabOptions from '@/components/prints/SendCheckLabOptions.vue'
import ResultLabelOptions from '@/components/prints/ResultLabelOptions.vue'

export default {

  components: {
    quillEditor,
    CheckSetType,
    AddNewCheckNote,
    LabLabelOptions,
    SendCheckLabOptions,
    ResultLabelOptions,
  },

  filters: {
    sumdate,
    formatPrice,
  },
  props: {
    loadingData: {
      type: Boolean,
      default: false,
    },
    opdData: {
      type: Object,
      default: () => {
      },
    },
  },

  setup(props, { emit }) {
    const editorOption = {
      placeholder: '',
      modules: {
        toolbar: {
          container: [
            [{ list: 'ordered' }, { list: 'bullet' }], // List
            [{ indent: '-1' }, { indent: '+1' }], // Indent
          ],
          handlers: {
            image(val) {
              if (val) {
                document.querySelector('.quill-img input').click()
              } else {
                this.quill.format('image', false)
              }
            },
          },
        },
      },
    }

    const loading = ref(true)
    const check_list = ref([])
    const opdDataLocal = ref({})
    const loadingUpdate = ref(false)
    const labListTable = ref([])
    const options = ref({})
    const columns = ref([
      {
        text: i18n.t('examination_set'), align: 'start', value: 'opdchecking_name', width: 200,
      },
      { text: i18n.t('examination_detail'), value: 'opdchecklist_name', width: 200 },
      {
        text: i18n.t('detectable_value'), value: 'detectable_value', width: 100, align: 'center',
      },
      {
        text: i18n.t('reference_value'), value: 'reference_value', width: 100, align: 'center',
      },
      {
        text: i18n.t('previous_results'),
        value: 'opdchecklist_ref_last',
        width: 100,
        align: 'center',
      },

    ])

    const note_name = ref('')
    const suggestionList = ref([])
    const search = ref('')
    const isAddNewCheckNote = ref(false)
    const userAnalysis = ref([])
    const userCheck = ref([])
    const opd_user_anlyst = ref('')
    const opd_user_inspector = ref('')
    const processUpdate = ref(false)
    const isPrintLabLabel = ref(false)
    const isSendCheckLab = ref(false)
    const isPrintResultLab = ref(false)

    const { userRoleList } = userRole

    const { checkNoteList } = checkNote

    const { labList, labValueUpdate, labUserUpdate } = lab

    const updateLabUser = () => {
      processUpdate.value = true
      labUserUpdate({
        opd_id: props.opdData.opd_id,
        opd_note: opdDataLocal.value.opd_note,
        opd_user_anlyst: opdDataLocal.value.opd_user_anlyst || '',
        opd_user_inspector: opdDataLocal.value.opd_user_inspector || '',
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        processUpdate.value = false
        emit('onUpdate')
      })
    }

    const getUserAnalysis = () => {
      userRoleList({
        role_id: [1, 2, 3],
      }).then(res => {
        userAnalysis.value = res
      })
    }

    const getSuggestionList = async () => {
      checkNoteList({
        searchtext: '',
        active_page: 1,
        per_page: '0',
      }).then(res => {
        suggestionList.value = res.data
      })
    }

    const getUserCheck = () => {
      userRoleList({
        role_id: [1, 3],
      }).then(res => {
        userCheck.value = res
      })
    }

    const getLabList = async () => {
      labList({
        opd_id: props.opdData.opd_id,
      }).then(res => {
        console.log('res : ', res)
        labListTable.value = res.opdchecklist
        loading.value = false
      })
    }

    const updateLabListCheck = item => {
      labValueUpdate({
        opdchecklist_id: item.opdchecklist_id,
        opdchecklist_value: item.opdchecklist_value,
        opdchecklist_ref: item.opdchecklist_ref ? item.opdchecklist_ref.checkref_name : '',
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('onUpdate')
      })
    }

    onMounted(() => {
      opdDataLocal.value = JSON.parse(JSON.stringify(props.opdData))
      options.value.page = 1
      options.value.itemsPerPage = -1
      getLabList()
      getSuggestionList()
      getUserAnalysis()
      getUserCheck()
    })

    watch(() => props.opdData, value => {
      opdDataLocal.value = JSON.parse(JSON.stringify(value))
      options.value.page = 1
      options.value.itemsPerPage = -1
      getLabList()
      getSuggestionList()
      getUserAnalysis()
      getUserCheck()
    })

    watch([note_name], value => {
      opdDataLocal.value.opd_note = `${opdDataLocal.value.opd_note ? opdDataLocal.value.opd_note : ''} ${value[0]}`
    })

    const onPrint = () => {
      emit('onUpdate')
    }

    return {
      isAddNewCheckNote,
      editorOption,
      loading,
      loadingUpdate,
      check_list,
      columns,
      opdDataLocal,
      options,
      labListTable,
      note_name,
      search,
      suggestionList,
      isSendCheckLab,
      userAnalysis,
      userCheck,
      opd_user_anlyst,
      processUpdate,
      opd_user_inspector,
      updateLabUser,
      updateLabListCheck,
      getSuggestionList,
      isPrintLabLabel,
      isPrintResultLab,
      onPrint,
      icons: {
        mdiCheck,
        mdiPlus,
        mdiPrinter,
      },

    }
  },

}
</script>

<style lang='scss'>
.ql-toolbar.ql-snow {
  border: 1px solid #ccc;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  box-sizing: border-box;
  font-family: 'Kanit', sans-serif;
  padding: 8px;
}

.ql-container {
  box-sizing: border-box;
  font-family: 'Kanit', sans-serif;
  font-size: 16px;
  height: 200px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  margin: 0px;
  position: relative;
}

</style>
