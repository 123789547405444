<template>
  <v-card>
    <v-card-title>
      <span>{{ opdDataLocal.opd_code }}</span>
      <v-spacer></v-spacer>
      {{ opdDataLocal.room_name }}
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
        >
          <p class=" font-weight-semibold mb-1">
            {{ $t("general_information") }} ({{ $t("history_washing_by") }} : {{ opdDataLocal.user_fullname }})
          </p>
          <v-divider
            class="mt-0 mb-2"
          ></v-divider>
          <v-row>
            <v-col
              cols="12"
              md="6"
              lg="3"
            >
              <v-text-field
                v-model="opdDataLocal.opd_t"
                label="T :"
                dense
                outlined
                class="my-0"
                hide-details
                suffix="C."
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="3"
            >
              <v-text-field
                v-model="opdDataLocal.opd_p"
                label="PR :"
                dense
                outlined
                class="my-0"
                hide-details
                suffix="/min."
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="3"
            >
              <v-text-field
                v-model="opdDataLocal.opd_rr"
                label="RR :"
                dense
                outlined
                class="my-0"
                hide-details
                suffix="/min."
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="3"
            >
              <v-text-field
                v-model="opdDataLocal.opd_bp"
                label="BP :"
                dense
                outlined
                class="my-0"
                hide-details
                suffix="/mmHg."
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="3"
            >
              <v-text-field
                v-model="opdDataLocal.opd_w"
                :label="$t('weight')"
                dense
                outlined
                class="my-0"
                hide-details
                :suffix="$t('kg')"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="3"
            >
              <v-text-field
                v-model="opdDataLocal.opd_h"
                :label="$t('height')"
                dense
                outlined
                class="my-0"
                hide-details
                :suffix="$t('cm')"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="3"
            >
              <v-text-field
                v-model="opdDataLocal.opd_bmi"
                label="BMI :"
                dense
                outlined
                class="my-0"
                hide-details
                disabled
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="3"
            >
              <v-text-field
                v-model="opdDataLocal.opd_fbs"
                label="FBS :"
                dense
                outlined
                class="my-0"
                hide-details
                suffix="mg/dl"
              ></v-text-field>
            </v-col>
          </v-row>
          <p class="font-weight-semibold mt-3 mb-1">
            {{ $t("important_symptoms_current_history") }}
          </p>
          <v-divider class="mb-2"></v-divider>

          <v-row>
            <v-col
              cols="12"
              lg="4"
              class=""
            >
              <v-textarea
                v-model="opdDataLocal.opd_cc"
                dense
                label="CC"
                hide-details
                outlined
                rows="3"
              ></v-textarea>
            </v-col>
            <v-col
              cols="12"
              lg="4"
            >
              <v-textarea
                v-model="opdDataLocal.opd_hpi"
                dense
                outlined
                label="HPI"
                rows="3"
                hide-details
              ></v-textarea>
            </v-col>
            <v-col
              cols="12"
              lg="4"
            >
              <v-textarea
                v-model="opdDataLocal.opd_pmh"
                outlined
                dense
                label="PMH"
                rows="3"
                hide-details
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              class="pt-0"
            >
              <v-radio-group
                v-model="opdDataLocal.opd_fag"
                hide-details
                class="mt-0"
                dense
                row
              >
                <label class="me-2  font-weight-semibold">{{ $t("smoking") }} : </label>
                <v-radio

                  v-for="(item, index) in smoking"
                  :key="index"

                  :value="item.value"
                >
                  <template v-slot:label>
                    <span
                      v-if="$i18n.locale == 'th'"
                    >
                      {{ item.th }}
                    </span>
                    <span
                      v-if="$i18n.locale == 'en'"
                    >{{ item.en }}</span>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
            <v-col
              cols="12"
              class="pt-0"
            >
              <v-radio-group
                v-model="opdDataLocal.opd_alcohol"
                hide-details
                class="mt-0"
                row
                dense
              >
                <label class="me-2 font-weight-semibold">{{ $t("drink_alcohol") }} : </label>
                <v-radio
                  v-for="(item, index) in booze"
                  :key="index"
                  :value="item.value"
                >
                  <template v-slot:label>
                    <span
                      v-if="$i18n.locale == 'th'"
                    >
                      {{ item.th }}
                    </span>
                    <span
                      v-if="$i18n.locale == 'en'"
                    >{{ item.en }}</span>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <p class="font-weight-semibold mt-3 mb-1">
            {{ $t("physical_examination") }}
          </p>
          <v-divider class="mb-2"></v-divider>

          <v-row>
            <v-col
              cols="12"
              lg="6"
            >
              <v-textarea
                v-model="opdDataLocal.opd_pe"
                dense
                outlined
                rows="3"
                label="PE"
                hide-details
              ></v-textarea>
            </v-col>
            <v-col
              cols="12"
              lg="6"
            >
              <v-textarea
                v-model="opdDataLocal.opd_ga"
                dense
                outlined
                label="GA"
                rows="3"
                hide-details
              ></v-textarea>
            </v-col>
          </v-row>
          <p class="font-weight-semibold mt-3 mb-1">
            {{ $t("diagnosis") }}
          </p>
          <v-divider class="mb-2"></v-divider>

          <v-row>
            <v-col
              cols="12"
            >
              <v-textarea
                v-model="opdDataLocal.opd_dx"
                dense
                outlined
                label="DX"
                rows="3"
                hide-details
              ></v-textarea>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        :loading="loading"
        :disabled="loading"
        @click="updateChecking"
      >
        {{ $t('save') }}
      </v-btn>
      <v-btn
        color="secondary"
        outlined
        @click="getDefault"
      >
        {{ $t('default') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { ref, watch, onMounted } from '@vue/composition-api'
import booze_data from '@/@fake-db/data/booze_data.json'
import smoking_data from '@/@fake-db/data/smoking_data.json'
import editCheck from '@/api/queue/editCheck'
import store from '@/store'

export default {
  props: {
    opdData: {
      type: Object,
      default: () => { },
    },
  },
  setup(props, { emit }) {
    const smoking = ref(smoking_data.data)
    const booze = ref(booze_data.data)
    const opdDataLocal = ref({})
    const loading = ref(false)
    const getDefault = () => {
      opdDataLocal.value = JSON.parse(JSON.stringify(props.opdData))
    }

    onMounted(() => {
      opdDataLocal.value = JSON.parse(JSON.stringify(props.opdData))
    })
    watch(() => props.opdData, val => {
      opdDataLocal.value = JSON.parse(JSON.stringify(val))
    })

    const { checkEditUpdate } = editCheck

    const updateChecking = () => {
      loading.value = true
      checkEditUpdate(opdDataLocal.value).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        store.commit('app/setStatusUpdateHeader')
        loading.value = false
        emit('onUpdate')
      })
    }
    watch(() => opdDataLocal.value.opd_w, value => {
      if (value) {
        opdDataLocal.value.opd_bmi = (value / ((opdDataLocal.value.opd_h / 100) * (opdDataLocal.value.opd_h / 100))).toFixed(2)
      }
    })

    watch(() => opdDataLocal.value.opd_h, value => {
      if (value) {
        opdDataLocal.value.opd_bmi = (opdDataLocal.value.opd_w / ((value / 100) * (value / 100))).toFixed(2)
      }
    })

    return {
      getDefault,
      opdDataLocal,
      smoking,
      loading,
      booze,
      updateChecking,
    }
  },
}
</script>
